import React, {useState, useEffect} from 'react';
import './App.css';
import { Routes, Route } from "react-router-dom";
import SideBar from './components/SideBar';
import Footer from './components/Footer';
import Header from './components/Header';
import SecondaryMenus from './components/SecondaryMenus';
import Index from './components/Home/Index';
import Login from './components/Auth/Login';
import LoginAlert from './components/LoginAlerts';

function App() {
  // let navigate = useNavigate();
  const [isauthenticated, setAuth] = useState(false);
  const shipment = localStorage.getItem('shipment');

  const confirmAuth = () =>{
    if (shipment === null) {
      setAuth(false);
    }else{
      setAuth(true);
    }
  }

 
useEffect(() => {
  confirmAuth();  
}, [])
    if (isauthenticated) {

        return (
          <div> 
              {/* <Footer/> */}
                <div className="page-content footer-clear">
                    <Header/>  
                    <Routes>   
                    <Route path='/shipment' element={<Index/>}/>                        
                    <Route path='/' element={<Login/>}/>                        
                    </Routes>
                </div>
                <SideBar/>
                <SecondaryMenus/>  
                {/* <Histories/>  */}
                <script src="/scripts/bootstrap.min.js"></script>
                <script src="/scripts/custom.js"></script>                                     
          </div>
        );
    }else{
      return (
        <div> 
          <div className="">
              <Routes>   
                <Route path='/' element={<Login/>} />
              </Routes>
          </div>      
          <SideBar/>  
          {/* <LoginAlert/>              */}
          <script src="/scripts/bootstrap.min.js"></script>
          <script src="/scripts/custom.js"></script>                                     
        </div>
      );
    } 
}

export default App;
