import React, {useEffect, useState} from 'react';
import { Fragment } from 'react';
import { Link } from 'react-router-dom';
const Index = () => {

    let shipment = localStorage.getItem('shipment');
    if (shipment != null) {
        shipment = JSON.parse(shipment);
    }
    let theme = localStorage.getItem('SWIFTEX LOGISTICS-Theme');
    if (theme === 'dark-mode') {
        theme = 'dark'
     }else{
      theme = 'light'
     }
     const [currentTheme, setCurrentTheme] = useState(theme);

    useEffect(() => {
        document.title = 'SWIFTEX SHIPMENT TRACKED';
        if(window && window.init_template){
            window.init_template();
        } 
        
        window.addEventListener('themeChanged', function (e) {
            let theme = localStorage.getItem('SWIFTEX LOGISTICS-Theme');
            if (theme !== null) {
                if (theme === 'dark-mode') {
                   setCurrentTheme('dark')
                }else{
                   setCurrentTheme('light') 
                }
               
            }             
       }, false);

    });

   return (

<div className='container px-sm-4 px-0 ' style={{marginTop: '80px'}}>    
    {shipment !== null &&
        <article className="card bg-theme color-theme shadow-sm">
         <header className="card-header bg-theme color-theme"> Shipment / Tracking </header>
         <div className="card-body">
           <div className='font-24 text-center mb-4'>TRACKING ID: <strong className='text-success'>{shipment.tracking_id}</strong></div>
           <hr/>
           <article className="card">
             <div className="card-body row g-4">
                
               <div className="col-md-3 col-6 font-16 "> <strong>Current Location:</strong> <br /> <span className='text-success'>{shipment.current_location}</span>  </div>
               <div className="col-md-3 col-6 font-16"> <strong>Origin Address:</strong> <br /><span className='opacity-70'>{shipment.origin_address}</span>  </div>
               <div className="col-md-3 col-6 font-16"> <strong>Destination Address:</strong> <br /> <span className='opacity-70'>{shipment.destination_address}</span> </div>
               <div className="col-md-3 col-6 font-16"> <strong>Estimated Delivery time:</strong> <br /><span className='opacity-70'>{shipment.estimated_delivery_time}</span> </div>
               <div className="col-md-3 col-6 font-16"> <strong>Shipping BY:</strong> <br /> <span className='opacity-70'>{shipment.shipping_by}, | <i className="fa fa-phone" /> {shipment.shipping_phone}</span> </div>
               <div className="col-md-3 col-6 font-16"> <strong>Status:</strong> <br /> <span className='opacity-70'>{shipment.current_status}</span> </div>
               <div className="col-md-3 col-6 font-16"> <strong>Order ID:</strong> <br /> <span className='opacity-70'>#{shipment.order_id}</span> </div>
               <div className="col-md-3 col-6 font-16"> <strong>Receiver:</strong> <br /> <span className='opacity-70'>{shipment.client_name} </span> </div>
             </div>
           </article>
           <div className="track">
            {shipment.current_status === shipment.status_1 &&
            <Fragment>
            <div className="step active"> <span className="icon"> <i className="fa fa-check" /> </span> <span className="text color-theme">{shipment.status_1}</span> </div>
            <div className="step"> <span className="icon"> <i className="fa fa-user" /> </span> <span className="text color-theme"> {shipment.status_2}</span> </div>
            <div className="step"> <span className="icon"> <i className="fa fa-truck" /> </span> <span className="text color-theme"> {shipment.status_3} </span> </div>
            <div className="step"> <span className="icon"> <i className="fa fa-box" /> </span> <span className="text color-theme">{shipment.status_4}</span> </div>
            </Fragment>
            }

            {shipment.current_status === shipment.status_2 &&
            <Fragment>
            <div className="step active"> <span className="icon"> <i className="fa fa-check" /> </span> <span className="text color-theme">{shipment.status_1}</span> </div>
            <div className="step active"> <span className="icon"> <i className="fa fa-user" /> </span> <span className="text color-theme"> {shipment.status_2}</span> </div>
            <div className="step"> <span className="icon"> <i className="fa fa-truck" /> </span> <span className="text color-theme"> {shipment.status_3} </span> </div>
            <div className="step"> <span className="icon"> <i className="fa fa-box" /> </span> <span className="text color-theme">{shipment.status_4}</span> </div>
            </Fragment>
            }

            {shipment.current_status === shipment.status_3 &&
            <Fragment>
            <div className="step active"> <span className="icon"> <i className="fa fa-check" /> </span> <span className="text color-theme">{shipment.status_1}</span> </div>
            <div className="step active"> <span className="icon"> <i className="fa fa-user" /> </span> <span className="text color-theme"> {shipment.status_2}</span> </div>
            <div className="step active"> <span className="icon"> <i className="fa fa-truck" /> </span> <span className="text color-theme"> {shipment.status_3} </span> </div>
            <div className="step"> <span className="icon"> <i className="fa fa-box" /> </span> <span className="text color-theme">{shipment.status_4}</span> </div>
            </Fragment>
            }

            {shipment.current_status === shipment.status_4 &&
            <Fragment>
            <div className="step active"> <span className="icon"> <i className="fa fa-check" /> </span> <span className="text color-theme">{shipment.status_1}</span> </div>
            <div className="step active"> <span className="icon"> <i className="fa fa-user" /> </span> <span className="text color-theme"> {shipment.status_2}</span> </div>
            <div className="step active"> <span className="icon"> <i className="fa fa-truck" /> </span> <span className="text color-theme"> {shipment.status_3} </span> </div>
            <div className="step active"> <span className="icon"> <i className="fa fa-box" /> </span> <span className="text color-theme">{shipment.status_4}</span> </div>
            </Fragment>
            }

             </div>
           <hr />
           {shipment.important_notification !== '' &&
           <div className="card card-style alert alert-dismissible border border-fade-yellow show fade p-0 mb-3">
                <div className="content my-3">
                    <div className="d-flex">
                        <div className="align-self-center no-click">
                            <i className="bi bi-exclamation-triangle font-36 color-yellow-dark d-block"></i>
                        </div>
                        <div className="align-self-center no-click">
                            <p className="color-theme mb-0 font-500 font-14 ps-3 pe-4 line-height-s">
                                {shipment.important_notification}
                            </p>
                        </div>
                    </div>
                </div>
            </div>
           }
           


           <ul className="row">
             <li className="col-md-4">
               <figure className="itemside mb-3">
                {shipment.product_image !== '' && 
                <div className="aside"><img src={shipment.product_image} className="img-sm border" /></div>
                }
                 <figcaption className="info align-self-center">
                   <p className="title color-theme">{shipment.product_description}</p> <span className="text-muted">{shipment.product_price} </span>
                 </figcaption>
               </figure>
             </li>
           </ul>
           <hr />
           <Link to="/" className="btn btn-warning" data-abc="true"> <i className="fa fa-chevron-left" /> Track Again</Link>
         </div>
       </article>
    }
</div>
  )
}

export default Index