import React, {useState, useEffect}  from 'react';
import { Link } from "react-router-dom";
import { useMutation } from "react-query";
import apiClient from "../../http-common";
import { useNavigate } from "react-router-dom";
import {
  triggerSuccess,
  triggerError,
  setErrorMessage,
  setSuccessMessage,
} from '../Request/StatusFormatter';
import Header from '../Header';

const Login = () => {
  let navigate = useNavigate();
    const initialLogin = {
        code: '',
    }
    const [loginForm, setLoginForm] = useState(initialLogin);
    const onChange = (e) =>
    setLoginForm({ ...loginForm, [e.target.name]: e.target.value });

    const { isLoading: isSendingRequest, mutate: postLogin } = useMutation(
        async () => {
          return await apiClient.post(`/api/tracking`, {
            code: loginForm.code,
          });
        },
        {
          onSuccess: (res) => {
            if (res.data.message === 'unverified') {
              navigate('/verify-user');
            } else {              
            setSuccessMessage(res.data);
            triggerSuccess();
            localStorage.setItem('shipment', JSON.stringify(res.data.shipment));
            window.location.href = '/shipment';
            }
            
          },
          onError: (err) => {
            setErrorMessage(err.response?.data || err);  
            triggerError();
          },
        }
      );
      const onSubmit = async() =>{
        await apiClient.get("/sanctum/csrf-cookie").then(() => {
          postLogin();
        })
      }   

      useEffect(() => {
        document.title = 'SWIFTEX TRACKING';
      });
  return (
    <section>
      <Header/>
    <div className="container-fluid pb-4" style={{backgroundImage: 'url(https://res.cloudinary.com/dzxyvdq14/image/upload/v1671443379/ser-bgdark.png)'}}>
      <h2 className='text-center mb-4 h2 fw-bolder text-white' style={{paddingTop: '100px'}}>TRACK YOUR SHIPMENT HERE</h2>
      <p className='text-center font-16 text-white opacity-50'>Track Swiftex shipments with one of the convenient tools below. Want more visibility of deliveries to your home?</p>
      <div className='row'>
        <div className='col-md-4'></div>
        <div className='col-md-4'> 
          <div className="card bg-transparent" >
                <div className="card-body mx-3 p-2  bg-transparent rounded-m">
                  {/* <div className="text-center d-flex justify-content-center mb-4">
                    <img src="https://res.cloudinary.com/dzxyvdq14/image/upload/v1668416937/Swiftexitaliv.png" height="60" alt="logo"/>
                  </div> */}

                  <h1 className="font-30 font-800 mb-0 text-center text-white">Shipment Tracking</h1>
                  <p className='text-center' style={{color: '#eff1f0'}}>Fill the form below to track your shipment.</p>
                  
                  <div className="form-custom form-label form-border form-icon mb-3" style={{border: '1px solid #ffffff', borderRadius: '8px'}}>
                      <i className="text-white bi bi-person-circle font-13"></i>
                      <input style={{color: '#f9fbfa'}} autoFocus='off' autoComplete="off" type="text" name="code" onChange={onChange} className="form-control login-forms rounded-xs" id="code" placeholder="enter tracking code"/>
                      <label htmlFor="code" className="text-white">Tracking Code</label>
                      <span style={{color: '#eff1f0'}}>(required)</span>
                  </div>
                  
                  
                  <div onClick={onSubmit} className="btn btn-full gradient-green shadow-bg shadow-bg-s mt-4">
                    {isSendingRequest? <span>tracking...</span>: <span>Track Shipment</span>}
                  </div>
              </div>
          </div>
        </div>
        <div className='col-md-4'></div>

      </div>
    </div>

    {/* Portfolio Section */}
<div className="container-fluid" style={{backgroundColor: '#efefef'}} >
  {/* Card */}
  <div  className="card border-0 shadow-none bg-transparent text-inherit transition-3d-hover p-4 p-md-7">
    <div className="row">
      <div className="col-lg-5 order-lg-2 mb-5 mb-lg-0">
        <div className="d-flex flex-column h-100">
          <div className="">
            <h2 className="h1">How to track packages</h2>
            <p className="text-body">Tracking a package with Swiftex is easy.  Locate your Swiftex package using a tracking number, track by reference or track by transportation control number (TCN). You can also track by using a Swiftex Office order number or the number found on your door tag. Use one of the convenient options below to gain insight on your upcoming delivery.</p>
          </div>
          {/* Testimonials */}
          <div className="card shadow-none p-4 ">
            <div className="mb-3">
              <img className="clients mr-auto" src="https://res.cloudinary.com/goldtrustx/image/upload/v1623563253/kaplan_ivosdj.svg" alt="SVG Logo" />
            </div>
            <div className="mb-3">
              <blockquote className="text-dark">"The tracking experience was awesome, unlike other companies Swiftex makes tracking painless"</blockquote>
            </div>
          </div>
          {/* End Testimonials */}
        </div>
      </div>
      <div className="col-lg-7 order-lg-1">
        {/* Info */}
        <div className="mb-5">
          <img className="img-fluid rounded-lg" src="https://res.cloudinary.com/dzxyvdq14/image/upload/v1671444873/trackerbus.png" alt="Image Description" />
        </div>
        <div className="row">
          <div className="col-sm-6 mt-4">
            <h4>Not going to be home to receive your Swiftex package? </h4>
            <p className="text-body">Keep your packages safe and holiday gifts a secret by controlling when and where they are delivered. If your package is already on its way, ask us to redirect it to one of our thousands of convenient pick-up locations. All you need is the shipment tracking ID to get started.</p>
          </div>
          <div className="col-sm-6 mt-4">
            <h4>Want to learn more about how to prevent package theft?</h4>
            <p className="text-body">As online shopping and the number of packages delivered to homes grow, so do concerns over package security. With Swiftex Delivery Manager, we've got options to have packages delivered to nearby locations instead of your porch. See what else others are doing to keep their packages safe and how you can, too.</p>
          </div>
        </div>
        {/* End Info */}
      </div>
    </div>
  </div>
  {/* End Card */}
</div>
{/* End Portfolio Section */}

    </section>
  )
}

export default Login