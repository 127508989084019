import React, {Fragment} from 'react';
import { Link } from "react-router-dom";

const SideBar = () => {

    const postlogout = () =>{
        localStorage.removeItem('shipment');
        window.location.href = '/';                
    }
    
    const switchTheme = () => {
        const modeEvent = new Event('themeChanged');
        window.dispatchEvent(modeEvent);
    }
    const hideNavigation = () => {
        var togglerFooter = document.querySelector('#footer-toggle');
        if(togglerFooter){
            togglerFooter.click();
        }
    } 

  return (
    <div id="menu-sidebar" className="offcanvas offcanvas-start offcanvas-attached">
        {/* <!-- menu-size will be the dimension of your menu. If you set it to smaller than your content it will scroll--> */}
        <div className="menu-size" style={{width: "230px"}}>
            {/* <!-- Menu Title--> */}
            <div className="pt-3">
                <div className="page-title sidebar-title d-flex">
                    <div className="align-self-center me-auto">
                        {/* <p className="color-highlight">{user.name}</p>
                        <div className='font-16'>{user.email}</div> */}
                    </div>
                    <div className="align-self-center ms-auto">
                        <Link to="/"
                        data-bs-toggle="dropdown"
                        className="icon gradient-green shadow-bg shadow-bg-s rounded-m">
                            {/* <img src={user.profile} width="45" className="rounded-m" alt="img"/> */}
                        </Link>
                        {/* <!-- Menu Title Dropdown Menu--> */}
                        <div className="dropdown-menu">
                            <div className="card card-style shadow-m mt-1 me-1">
                                <div className="list-group list-custom list-group-s list-group-flush rounded-xs px-3 py-1">
                                   
                                    <Link to="/profile" className="list-group-item">
                                        <i className="has-bg gradient-yellow shadow-bg shadow-bg-xs color-white rounded-xs bi bi-person-circle"></i>
                                        <strong className="font-13">Account</strong>
                                    </Link>
                                    <Link to="/" className="list-group-item">
                                        <i className="has-bg gradient-red shadow-bg shadow-bg-xs color-white rounded-xs bi bi-power"></i>
                                        <strong className="font-13">Log Out</strong>
                                    </Link>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="divider divider-margins mb-3 opacity-50"></div>

                {/* <!-- Main Menu List--> */}
                <div className="list-group list-custom list-menu-large">
                    <Link onClick={hideNavigation} to="/" id="lome-link" className="list-group-item">
                        <i translate="no" className="material-icons-sharp bi bg-blue-dark shadow-bg shadow-bg-xs bi-house-fill"></i>
                        <div>Home</div>
                        
                    </Link>
                   
                    <a onClick={hideNavigation} href="https://swiftexlogistics.com/taxation" id="loan-link" className="list-group-item">
                        <i translate="no" className="material-icons-sharp bi bg-blue-dark shadow-bg shadow-bg-xs bi-bar-chart-line"></i>
                        <div>Taxation</div>
                    </a>   

                    <a onClick={hideNavigation} href="https://swiftexlogistics.com/about" id="lending-link" data-submenu="sub1" className="list-group-item">
                        <i translate="no" className="material-icons-sharp bi bg-blue-dark shadow-bg shadow-bg-xs bi-download"></i>
                        <div>About</div>
                    </a>        

                    <a onClick={hideNavigation} href="https://swiftexlogistics.com/warehouse" id="loan-link" className="list-group-item">
                        <i translate="no" className="material-icons-sharp bi bg-blue-dark shadow-bg shadow-bg-xs  bi-server"></i>
                        <div>Warehouses</div>
                    </a>

                    <a onClick={hideNavigation} href="https://swiftexlogistics.com/contacts" id="wallet-link" className="list-group-item">
                        <i translate="no" className="material-icons-sharp bi bg-blue-dark shadow-bg shadow-bg-xs bi-people"></i>
                        <div>Contact</div>
                    </a>

                    <a onClick={hideNavigation} href="https://swiftexlogistics.com/faqs" id="lending-link" data-submenu="sub1" className="list-group-item">
                        <i translate="no" className="material-icons-sharp bi bg-blue-dark shadow-bg shadow-bg-xs bi-box-arrow-right"></i>
                        <div>Knowledge Base</div>
                    </a>

                    <a onClick={hideNavigation} href="https://swiftexlogistics.com/customs" id="repayment-link" className="list-group-item">
                        <i translate="no" className="material-icons-sharp bi bg-blue-dark shadow-bg shadow-bg-xs bi-bar-chart-steps"></i>
                        <div>Customs</div>
                    </a>  

                    <a href="/#" className="list-group-item" data-toggle-theme data-trigger-switch="switch-1">
                        <i translate="no" className="material-icons-sharp bi bg-blue-dark shadow-bg shadow-bg-xs bi-lightbulb-fill"></i>
                        <div>Dark Mode</div>
                        <div className="form-switch ios-switch switch-green switch-s me-2">
                            <input onChange={switchTheme} type="checkbox" data-toggle-theme className="ios-input" id="switch-1"/>
                            <label className="custom-control-label" htmlFor="switch-1"></label>
                        </div>
                    </a>
                </div>

                <div className="divider divider-margins mb-3 opacity-50"></div>
               

                <div className="divider divider-margins opacity-50"></div>

                {/* <!-- Useful Links--> */}
                <h6 className="opacity-40 px-3 mt-n2 mb-0">Useful Links</h6>
                <div className="list-group list-custom list-menu-small">
                    
                    <Link to="/" onClick={postlogout} className="list-group-item">
                        <i className="bi bi-bar-chart-fill opacity-20 font-16"></i>
                        <div>Track Shipment</div>
                    </Link>
                </div>

                <div className="divider divider-margins opacity-50"></div>
                {/* <!-- Menu Copyright --> */}
                <p className="px-3 font-9 opacity-30 color-theme mt-n3">&copy; SwiftEx Logistics<i className="bi bi-heart-fill color-red-dark px-1"></i>swiftexlogistics.com</p>
            </div>
        </div>
    </div>
  )
}

export default SideBar